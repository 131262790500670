import logo from "./logo.svg";
import React, { useState } from "react";
import "./App.css";
import Album from "./components/Album";

function App() {
  const [houseId, setHouseId] = useState(60);

  return (
    <div>
      <Album houseId={houseId} setHouseId={setHouseId} />
    </div>
  );
}

export default App;
