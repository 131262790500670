import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import TwitterIcon from "@mui/icons-material/Twitter";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import surfer0 from "./surfers/0.png";
import surfer1 from "./surfers/1.png";
import surfer2 from "./surfers/2.png";
import surfer3 from "./surfers/3.png";
import surfer4 from "./surfers/4.png";
import surfer5 from "./surfers/5.png";
import surfer6 from "./surfers/6.png";
import surfer7 from "./surfers/7.png";
import surfer8 from "./surfers/8.png";
import surfer9 from "./surfers/9.png";
import surfer10 from "./surfers/10.png";
import surfer11 from "./surfers/11.png";
import surfer12 from "./surfers/12.png";
import shaka from "./surfers/shaka.png";

const surferMap = {
  0: surfer0,
  1: surfer1,
  2: surfer2,
  3: surfer3,
  4: surfer4,
  5: surfer5,
  6: surfer6,
  7: surfer7,
  8: surfer8,
  9: surfer9,
  10: surfer10,
  11: surfer11,
  12: surfer12,
};

const URL_S3 =
  "https://beachhouses.s3.us-west-2.amazonaws.com/all/148.mp4?ext=mp4";
const HOUSE_ID = 60;
function TwitterLink() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      <Link color="inherit" href="https://twitter.com/SolanaHouses">
        Solana Beach Houses
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const cards = [4, 5, 6, 7, 8, 9, 0, 1, 2, 3];
const font =
  "'Bakbak One', 'Roboto', 'Bungee Shade', 'Oi', 'Source Code Pro', 'Gluten', sans-serif";

const theme = createTheme({
  palette: {
    background: {
      default: "#EFE4E1",
    },
  },
  typography: {
    fontFamily: font,
  },
});

export default function Album(props) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="relative"
        sx={{
          display: "flex",
          height: "80px",
          alignItems: "flex-start",
          justifyContent: "flex-end",
          background: "transparent",
          color: "black",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "flex-end",
            mr: 2,
            "@media only screen and (max-width: 650px)": {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <Link
            fontFamily="Bungee Shade"
            variant="h4"
            color="inherit"
            // href=""
            sx={{
              textDecoration: "none",
              "@media only screen and (max-width: 650px)": {
                fontSize: "25px",
              },
              "&:hover": {
                backgroundColor: "transparent",
                cursor: "pointer",
              },
            }}
            noWrap
            onClick={() => props.setHouseId(60)}
          >
            Beach Houses
          </Link>
          <Typography
            // fontWeight="bold"
            color="inherit"
            sx={{
              margin: "0rem 2rem .1rem 2rem",
              "&:hover": {
                backgroundColor: "transparent",
                cursor: "pointer",
                textDecoration: "underline",
              },
              "@media only screen and (max-width: 800px)": {
                margin: "10px",
              },
            }}
            noWrap
            onClick={() => props.setHouseId(null)}
          >
            THE ISLANDS (coming soon)
          </Typography>
          <Button
            sx={{
              color: "#1DA1F2",
              position: "absolute",
              top: "22px",
              right: "100px",
              "&:hover": {
                backgroundColor: "transparent",
                cursor: "pointer",
              },
              "@media only screen and (max-width: 800px)": {
                display: "none",
              },
            }}
            variant="outlined"
          >
            Sign in (soon)
          </Button>
          <TwitterIcon
            sx={{
              color: "#1DA1F2",
              fontSize: "48px",
              position: "absolute",
              top: "15px",
              right: "30px",
              "&:hover": {
                backgroundColor: "transparent",
                cursor: "pointer",
              },
              "@media only screen and (max-width: 800px)": {
                display: "none",
              },
            }}
            onClick={(event) => (window.location.href = "https://twitter.com")}
          />
        </Toolbar>
      </AppBar>
      {props.houseId ? (
        <main>
          {/* Hero unit */}
          <Box
            sx={{
              // bgcolor: "background.paper",
              pt: 8,
              pb: 6,
            }}
          >
            <Container maxWidth="sm">
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="text.primary"
                gutterBottom
                fontFamily="Bungee Shade"
              >
                Beach House #60
              </Typography>
              <CardMedia
                component="video"
                alt="video"
                sx={{
                  // 16:9
                  borderRadius: "20px",
                  margin: "1rem",
                }}
                image={URL_S3}
                // alt="random"
                autoPlay
                muted
                // loop
                controls
              />
              <Typography
                variant="h5"
                align="center"
                color="text.secondary"
                paragraph
              >
                Welcome to House #60/250. This Surfer Hut has a Shaka Flag,
                Palms, a Watertoy Set and GET PITTED written in the sand! Check
                out its residents below.
              </Typography>
              <Stack
                sx={{ pt: 4 }}
                direction="row"
                spacing={2}
                justifyContent="center"
              >
                <Button variant="contained" href={URL_S3}>
                  Download file
                </Button>
                <Button
                  sx={{
                    color: "#1DA1F2",
                  }}
                  variant="outlined"
                >
                  Owner: CnXjnP...
                </Button>
              </Stack>
            </Container>
          </Box>
          <Container sx={{ py: 8 }} maxWidth="md">
            {/* End hero unit */}
            <Grid container spacing={4}>
              {cards.map((card) => (
                <Grid item key={card} xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardMedia
                      component="image"
                      sx={{
                        pt: "100%",
                      }}
                      image={require(`./surfers/${card}.png`)}
                      alt="random"
                    />
                    {/* <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Heading
                    </Typography>
                    <Typography>
                      This is a media card. You can use this section to describe
                      the content.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small">View</Button>
                    <Button size="small">Edit</Button>
                  </CardActions> */}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </main>
      ) : (
        <main>
          <Box
            sx={{
              // bgcolor: "background.paper",
              pt: 8,
              pb: 6,
            }}
          >
            <Container maxWidth="lg">
              <Stack>
                <Typography
                  component="h1"
                  variant="h2"
                  align="center"
                  color="text.primary"
                  gutterBottom
                  fontFamily="Bungee Shade"
                >
                  The Islands
                </Typography>
                <Button
                  sx={{
                    color: "#1DA1F2",
                  }}
                  variant="outlined"
                  onClick={() => props.setHouseId(60)}
                >
                  Visit your island (soon)
                </Button>
              </Stack>
              <CardMedia
                component="image"
                alt="image"
                sx={{
                  // 16:9
                  borderRadius: "10px",
                  margin: "1rem",
                  pt: "56%",
                }}
                image={shaka}
              />
            </Container>
          </Box>
        </main>
      )}
      {/* Footer */}
      <Box sx={{ p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Solana Surfers
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Visit us on Twitter!
        </Typography>
        <TwitterLink />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}
